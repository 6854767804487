/* Sign-In Button */
.sign-in-button {
  background-color: #805AD5;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

/* Profile Button in Corner */
.profile-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #805AD5;
  color: white;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}

/* Sidebar Styling */
.sidebar {
  position: fixed;
  right: 0;
  top: 0;
  width: 300px;
  height: 100vh;
  background-color: #2D3748;
  color: white;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

button {
  background-color: #E53E3E;
  color: white;
  padding: 10px;
  border: none;
  cursor: pointer;
}

/* Feedback Buttons */
.feedback-button {
  padding: 10px 20px;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.feedback-button.yes {
  background-color: #38A169; /* Green */
  margin-right: 10px;
}

.feedback-button.no {
  background-color: #E53E3E; /* Red */
}
